import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import GtrGlobalSolutionLayout from '@/modules/common/views/layouts/level-two/global-solution/global-solution.layout.vue'
import Container from 'typedi'
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { TransactionStatus } from '../../../common/enums/transaction-status'
import GtrEventTile from './event-tile/event-tile.view.vue'
import GtrEventMenu from './event-menu/event-menu.view.vue'

@Component({
  name: 'GtrCompanyEventsView',
  computed: {
    ...mapState('event', ['events', 'deletionRequests']),
    ...mapState('company', ['company']),
    ...mapState('security', ['currentUser']),
    ...mapState('module', ['gtrModules'])
  },
  components: {
    'gtr-event-tile': GtrEventTile,
    'gtr-event-menu': GtrEventMenu
  }
})
export default class GtrCompanyEventsView extends Vue {
  gtrModules!: Record<string, any>

  events!: Array<Record<string, any>>
  company!: Record<string, any>

  data () {
    return {
      search: '',
      liveSearch: '',
      upcomingSearch: '',
      _events: [],
      showDeletionRequests: false,
      loading: false,
      submitting: false,
      tables: {
        events: {
          headers: [
            {
              text: 'Name',
              align: 'start',
              sortable: true,
              value: 'name'
            },
            {
              text: 'Event Start Date',
              align: 'start',
              value: 'event_start_date',
              sortable: true,
              class: 'header-no-wrap'
            },
            {
              text: 'Event End Date',
              align: 'start',
              value: 'event_end_date',
              sortable: true,
              class: 'header-no-wrap'
            },
            {
              text: 'Modules',
              align: 'start',
              value: 'modules',
              sortable: false
            },
            {
              text: 'SSO',
              align: 'start',
              value: 'sso',
              sortable: false
            },
            {
              text: 'Participants',
              align: 'start',
              value: 'stats.participant_counts',
              sortable: true,
              class: 'header-no-wrap'
            },
            {
              text: 'Actions',
              align: 'start',
              value: 'actions',
              searchable: false,
              sortable: false,
              width: '90px'
            }
          ]
        },
        deletionRequests: {
          headers: [
            {
              text: 'Name',
              align: 'start',
              sortable: true,
              value: 'name'
            },
            {
              text: 'Requested By',
              align: 'start',
              sortable: true,
              value: 'requested_by'
            },
            {
              text: 'Reason',
              align: 'start',
              sortable: true,
              value: 'reason'
            },
            {
              text: 'Requested',
              align: 'start',
              sortable: true,
              value: 'created_at'
            },
            {
              text: 'Status',
              align: 'start',
              sortable: true,
              value: 'status'
            },
            {
              text: 'Approved By',
              align: 'start',
              sortable: true,
              value: 'approved_by'
            },
            {
              text: '',
              value: 'actions',
              searchable: false,
              sortable: false,
              width: '90px'
            }
          ],
          items: [],
          _events: []
        }
      },
      tab: null,
      eventToDelete: null,
      eventToShowActiveModules: null,
      eventCreateModal: false,
      hideDetails: false,
      addressErrorMessage: false
    }
  }

  async created () {
    // this.$bus.$on('gtr-event-created', this.handleEventCreatedEvent)
    this.$emit('update:layout', GtrGlobalSolutionLayout)
  }

  async mounted () {
    await this.fetchEvents()
  }

  private sleep (ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  // TODO: create a mixin for shared functionality of event creation. See also: company-dashboard.ts
  async handleEventCreatedEvent () {
    this.$data.eventCreateModal = false
    await this.fetchEvents()
  }

  private bannerFilesChange (file) {
    this.$data.newEvent.banner = file
  }

  private faviconFilesChange (file) {
    this.$data.newEvent.favicon = file
  }

  searchEvents (events: any[], searchTerm: string): any[] {
    searchTerm = searchTerm.toLocaleLowerCase()
    return events.filter(event => {
      return event.name.toLocaleLowerCase().includes(searchTerm) ||
        event.address.toLocaleLowerCase().includes(searchTerm) || (event.category &&
        event.category.toLocaleLowerCase().includes(searchTerm))
    })
  }

  get liveEvents () {
    if (this.companyEvents && this.companyEvents.length) {
      const filteredEvents = this.companyEvents.filter(event => event.event_active && event.status === 'ACTIVE')
      return this.searchEvents(filteredEvents, this.$data.liveSearch)
    }
    return []
  }

  get upcomingEvents () {
    if (this.companyEvents && this.companyEvents.length) {
      const filteredEvents = this.companyEvents.filter(event => (event.status === 'ACTIVE' || event.status === 'CREATED' || event.status === 'PENDING') && !event.event_active && !event.event_over)
      return this.searchEvents(filteredEvents, this.$data.upcomingSearch)
    }
    return []
  }

  get pastEvents () {
    if (this.companyEvents && this.companyEvents.length) {
      const pastEvents = this.companyEvents.filter(event => event.event_over && event.status !== 'ARCHIVED')
      const sortedPastEvents = pastEvents.sort((a, b) => a.active_end_date <= b.active_end_date ? 1 : -1)
      return sortedPastEvents
    }
    return []
  }

  get archivedEvents () {
    if (this.companyEvents && this.companyEvents.length) {
      const archivedEvents = this.companyEvents.filter(event => event.status === 'ARCHIVED')
      const sortedArchivedEvents = archivedEvents.sort((a, b) => a.active_end_date <= b.active_end_date ? 1 : -1)
      return sortedArchivedEvents
    }
    return []
  }

  get companyEvents () {
    if (this.events.length) {
      const { uuid } = this.$route.params
      return this.events.filter((c: Record<string, any>) => (c.uuid === uuid))[0].events ?? []
    }
    return []
  }

  // TODO: create a mixin for shared functionality of event creation. See also: company-dashboard.ts
  onFinishCreateEvent (value: boolean): void {
    this.$data.eventCreateModal = value
  }

  get currentUserAccessLevel () {
    return (this as any)?.currentUser?.access_level || null
  }

  handleEventView (event: any) {
    this.$store.dispatch('event/setEvent', event)
    this.$router.push({ name: 'level-two.event.event-info', params: { event_uuid: event.uuid } })
  }

  handleDeleteEvent (payload: any) {
    this.$data.eventToDelete = payload
  }

  activeModulesForEvent (event: any) {
    const activeModules: any[] = []
    if (event) {
      this.gtrModules.forEach((_module: any) => {
        event.modules.forEach((module: any) => {
          if (_module.identifier === module.module) {
            activeModules.push(_module)
          }
        })
      })
    }
    return activeModules
  }

  async handleDeleteEventAction (payload: any) {
    if (payload.confirm) {
      try {
        await this.$store.dispatch('event/removeEvent', { data: { deletion_reason: payload.reason.reason }, event_uuid: this.$data.eventToDelete.uuid })
        Container.get(Notification).success('Event successfully deleted.')
        await this.fetchEvents()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      }
    }
    this.$data.eventToDelete = null
  }

  async handleArchiveEvent (payload: any) {
    try {
      await this.$store.dispatch('event/changeEventStatus', {
        event_uuid: payload.uuid,
        data: { status: 'ARCHIVED' }
      })
      Container.get(Notification).success('Event successfully archived.')
      await this.fetchEvents()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async handleUnarchiveEvent (payload: any) {
    try {
      await this.$store.dispatch('event/changeEventStatus', {
        event_uuid: payload.uuid,
        data: { status: 'ACTIVE' }
      })
      Container.get(Notification).success('Event successfully unarchived.')
      await this.fetchEvents()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchEvents () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('event/loadEventsForCompany', { company_uuid: this.$route.params.uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchEventDeletionRequests () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('event/loadDeletionRequests')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async approve (item) {
    await this.$store.dispatch('event/updateDeletionRequest', {
      status: TransactionStatus.APPROVED,
      event_uuid: this.$route.params.event_uuid,
      deletion_request_uuid: item.uuid
    })
  }

  async decline (item) {
    await this.$store.dispatch('event/updateDeletionRequest', {
      status: TransactionStatus.DECLINED,
      event_uuid: this.$route.params.event_uuid,
      deletion_request_uuid: item.uuid
    })
  }

  async handleViewDeletionRequests () {
    if (this.$data.showDeletionRequests) {
      this.$data.showDeletionRequests = false
    } else {
      await this.fetchEventDeletionRequests()
      this.$data.showDeletionRequests = true
    }
  }
}
