import { Component, Prop, Vue } from 'vue-property-decorator'
import GtrEventMenu from '../event-menu/event-menu.view.vue'

@Component({
  name: 'GtrEventTile',
  components: {
    'gtr-event-menu': GtrEventMenu
  }
})
export default class GtrEventTile extends Vue {
  @Prop() event!: Record<string, any>

  @Prop() gtrModules!: Record<string, any>

  @Prop() currentUserAccessLevel!: string;

  monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  handleEventView () {
    this.$store.dispatch('event/setEvent', this.event)
    this.$router.push({ name: 'level-two.event.event-info', params: { event_uuid: this.event.uuid } })
  }

  activeModulesForEvent (event: any) {
    const activeModules: any[] = []
    if (event) {
      this.gtrModules.forEach((_module: any) => {
        event.modules.forEach((module: any) => {
          if (_module.identifier === module.module) {
            activeModules.push(_module)
          }
        })
      })
    }
    return activeModules
  }

  dateRange (startDateString: string, endDateString: string): string {
    const startDate = new Date(startDateString)
    const endDate = new Date(endDateString)
    let dateRangeString = this.monthAbbreviations[startDate.getUTCMonth()] + ' ' + startDate.getUTCDate()

    if (startDate.getUTCFullYear() !== endDate.getUTCFullYear()) {
      dateRangeString += ', ' + startDate.getUTCFullYear()
    }
    if (startDate.getUTCDate() !== endDate.getUTCDate() || startDate.getUTCMonth() !== endDate.getUTCMonth() || startDate.getUTCFullYear() !== endDate.getUTCFullYear()) {
      dateRangeString += ' - ' + this.monthAbbreviations[endDate.getUTCMonth()] + ' ' + endDate.getUTCDate()
    }
    dateRangeString += ', ' + endDate.getUTCFullYear()

    return dateRangeString
  }
}
